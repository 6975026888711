var __jsx = React.createElement;
import React, { useEffect, useReducer } from 'react';
var withLinkValidation = function withLinkValidation(WrappedComponent) {
  var WithLinkValidation = function WithLinkValidation(props) {
    var _useReducer = useReducer(function (x) {
        return x + 1;
      }, 0),
      _ = _useReducer[0],
      forceUpdate = _useReducer[1];
    var handleLinkClick = function handleLinkClick(event) {
      var _event$target, _event$target2;
      var parentLink = event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.closest('a');
      if ((event === null || event === void 0 ? void 0 : (_event$target2 = event.target) === null || _event$target2 === void 0 ? void 0 : _event$target2.tagName) === 'A' || parentLink) {
        var _event$target3, _window, _window$location, _localStorage;
        var target = (event === null || event === void 0 ? void 0 : (_event$target3 = event.target) === null || _event$target3 === void 0 ? void 0 : _event$target3.tagName) === 'A' ? event.target : parentLink;
        var host = (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.host;
        var siteId = (_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.getItem('instantPreviewSiteId');
        var href = target === null || target === void 0 ? void 0 : target.getAttribute('href');
        if (siteId && (href !== null && href !== void 0 && href.includes(host) || !(href !== null && href !== void 0 && href.includes('http')))) {
          target.setAttribute('href', (target === null || target === void 0 ? void 0 : target.getAttribute('href')) + ":".concat(siteId));
          forceUpdate();
        }
      }
    };
    useEffect(function () {
      document.addEventListener('click', handleLinkClick);
      return function () {
        document.removeEventListener('click', handleLinkClick);
      };
    }, []);
    return __jsx(WrappedComponent, props);
  };
  return WithLinkValidation;
};
export default withLinkValidation;