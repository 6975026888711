/**
 * @file Manages the labels
 */
var globalLabels = {
  weekday: {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat'
  },
  meridian: {
    am: 'am',
    pm: 'pm'
  }
};
var locationLabels = {
  address: 'Address',
  contactus: 'Give Us A Call',
  phone: 'Phone:',
  sms: 'Text:',
  fax: 'Fax:',
  email: 'Email:',
  addressCamelCase: 'Address:',
  emergency: 'EMERGENCY',
  hoursofoperation: 'Hours of Operation',
  mainhours: 'Main Hours',
  extendedhours: 'Extended Hours',
  emergencyhours: 'Emergency Hours',
  visitsite: 'Visit site',
  closed: 'Closed',
  open24hours: 'Open 24 hrs',
  map: 'Map',
  book: 'Book Appointment',
  call: 'Call',
  text: 'Text',
  bookGrid: 'Book',
  T: 'T:',
  F: 'F:',
  E: 'E:'
};
var navLabels = {
  SPECIALTY: 'Specialty Emergency Room',
  SKIP_LINK_CONTENT: 'Skip to Primary Content',
  SKIP_LINK_NAVIGATION: 'Skip Navigation'
};
var servicesBladeLabels = {
  readMore: 'Read More',
  learnMore: 'Learn More'
};
var staffLabels = {
  VIEWPROFILE: 'View Profile',
  ACCREDITATIONS: 'Accreditations'
};
var blogLabels = {
  READ_MORE: 'Read More',
  CATEGORY: 'Category',
  ALL: 'All'
};
var formLabels = {
  contactUsForm: {
    FORM_HEADING: 'Send Us an Email',
    NAME: 'NAME*',
    PHONE: 'PHONE*',
    EMAIL: 'EMAIL*',
    PERSONAL_MESSAGE: 'PERSONAL MESSAGE (OPTIONAL)',
    SUBMIT_BUTTON: 'Submit'
  },
  BookForm: {
    NAME: 'NAME*',
    ADDRESS: 'ADDRESS*',
    PHONE: 'PHONE*',
    SECONDARY_PHONE: 'SECONDARY PHONE*',
    EMAIL: 'EMAIL*',
    PETS_NAME: 'PET’S NAME*',
    CHECKIN_DATE: 'CHECK-IN DATE*',
    EMERGENCY_CONTACT_NAME: 'EMERGENCY CONTACT NAME*',
    EMERGENCY_CONTACT_NUMBER: 'EMERGENCY CONTACT NUMBER*',
    IS_EXISTING_CLIENT: 'ARE YOU AN EXISTING CLIENT?*',
    INTERESTED_SERVICE: 'WHAT SERVICES ARE YOU INTERESTED IN?',
    SOURCE_OF_KNOWLEDGE: 'HOW DID YOU HEAR ABOUT US?',
    NOTES: 'NOTES (OPTIONAL)',
    HONEY_BOT: 'USER NAME FIELD*'
  },
  LocationFinderForm: {
    STATE: 'States / Territories',
    HONEY_BOT: 'POSTAL CODE FIELD*',
    POSTAL_CODE: 'Postal Code (Up to 200 Miles)'
  },
  nvaContactUsForm: {
    FORM_HEADING: 'Send Us An Email',
    NAME: 'Name*',
    PHONE: 'Phone*',
    EMAIL: 'Email*',
    TITLE: 'Title*',
    BUSINESSNAME: 'Business Name*',
    BUSINESSADDRESS: 'Business Address*',
    PERSONAL_MESSAGE: 'Message',
    SUBMIT: 'Send'
  }
};
var formErrorMessages = {
  contactUsForm: {
    NAME_REQUIRED_ERROR: 'PLEASE ENTER NAME',
    NAME_ERROR: 'PLEASE ENTER A VALID NAME',
    PHONE_REQUIRED_ERROR: 'PLEASE ENTER PHONE NUMBER',
    PHONE_ERROR: 'PLEASE ENTER A VALID PHONE NUMBER',
    EMAIL_REQUIRED_ERROR: 'PLEASE ENTER EMAIL',
    EMAIL_ERROR: 'PLEASE ENTER A VALID EMAIL ADDRESS',
    EMAIL_SEND_ERROR: 'Something went wrong, please try after some time.'
  },
  BookForm: {
    NAME_REQUIRED_ERROR: 'PLEASE ENTER NAME',
    NAME_ERROR: 'PLEASE ENTER A VALID NAME',
    PHONE_REQUIRED_ERROR: 'PLEASE ENTER PHONE NUMBER',
    PHONE_ERROR: 'PLEASE ENTER A VALID PHONE NUMBER',
    SECONDARY_PHONE_REQUIRED_ERROR: 'PLEASE ENTER SECONDARY PHONE NUMBER',
    SECONDARY_PHONE_ERROR: 'PLEASE ENTER A VALID SECANDORY PHONE NUMBER',
    EMAIL_REQUIRED_ERROR: 'PLEASE ENTER EMAIL',
    EMAIL_ERROR: 'PLEASE ENTER A VALID EMAIL ADDRESS',
    ADDRESS_REQUIRED_ERROR: 'PLEASE ENTER ADDRESS',
    PET_NAME_REQUIRED_ERROR: "PLEASE ENTER PET'S NAME",
    CHECKIN_DATE_REQUIRED_ERROR: 'PLEASE ENTER DATE',
    CHECKIN_DATE_ERROR: 'PLEASE ENTER A VALID DATE',
    EMERGENCY_NAME_REQUIRED_ERROR: 'PLEASE ENTER EMERGENCY CONTACT NAME',
    EMERGENCY_NAME_ERROR: 'PLEASE ENTER A VALID EMERGENCY CONTACT NAME',
    EMERGENCY_CONTACT_REQUIRED_ERROR: 'PLEASE ENTER EMERGENCY CONTACT NUMBER',
    EMAIL_SEND_ERROR: 'Something went wrong, please try after some time.',
    EMERGENCY_CONTACT_ERROR: 'PLEASE ENTER A VALID EMERGENCY CONTACT NUMBER'
  },
  nvaContactUsForm: {
    NAME_REQUIRED_ERROR: 'PLEASE ENTER NAME',
    NAME_ERROR: 'PLEASE ENTER A VALID NAME',
    PHONE_REQUIRED_ERROR: 'PLEASE ENTER PHONE NUMBER',
    PHONE_ERROR: 'PLEASE ENTER A VALID PHONE NUMBER',
    EMAIL_REQUIRED_ERROR: 'PLEASE ENTER EMAIL',
    EMAIL_ERROR: 'PLEASE ENTER A VALID EMAIL ADDRESS',
    TITLE_REQUIRED_ERROR: 'PLEASE ENTER TITLE',
    TITLE_ERROR: 'PLEASE ENTER A VALID TITLE',
    BUSINAME_REQUIRED_ERROR: 'PLEASE ENTER BUSINESS NAME',
    BUSINAME_ERROR: 'PLEASE ENTER A VALID BUSINESS NAME',
    BUSIADD_REQUIRED_ERROR: 'PLEASE ENTER BUSINESS ADDRESS',
    BUSIADD_ERROR: 'PLEASE ENTER A VALID BUSINESS ADDRESS',
    EMAIL_SEND_ERROR: 'Something went wrong, please try after some time.'
  },
  locationFinderForm: {
    POSTAL_CODE_INVALID: 'Please enter a correct postal code',
    BUSINESS_TYPE_REQUIRED_ERROR: 'Please select at least one business type'
  }
};
var emailLabels = {
  contactUsForm: {
    NO_REPLY: 'noreply@nva.com',
    EMAIL_LOCATION_SUBJECT: 'Contact Us Form',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    MESSAGE: 'Message',
    EMAIL_CUSTOMER_SUBJECT: 'Thank you for your request'
  },
  BookForm: {
    NO_REPLY: 'noreply@nva.com',
    EMAIL_LOCATION_SUBJECT: 'Contact Us Form',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    ADDRESS: 'Address',
    SECONDARY_PHONE: 'Secondary Phone',
    PETS_NAME: 'Pet’s Name',
    CHECKIN_DATE: 'Check in Date',
    EMERGENCY_CONTACT_NAME: 'Emergency Contact Name',
    EMERGENCY_CONTACT_NUMBER: 'Emergency Contact Number',
    IS_EXISTING_CLIENT: 'Are you and existing client',
    INTERESTED_SERVICE: 'What service you are interested in',
    SOURCE_OF_KNOWLEDGE: 'How did you hear about us',
    NOTES: 'Note',
    EMAIL_CUSTOMER_SUBJECT: 'Thank you for your request'
  },
  contactUsFormNva: {
    NO_REPLY: 'noreply@nva.com',
    EMAIL_LOCATION_SUBJECT: 'Contact Us Form',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    TITLE: 'Title',
    BUSINESSNAME: 'Business Name',
    BUSINESSADDRESS: 'Business Address',
    MESSAGE: 'Message',
    EMAIL_CUSTOMER_SUBJECT: 'Thank you for your request'
  }
};
var BookFormServices = [{
  value: 'Training',
  label: 'Training'
}, {
  value: 'Boarding',
  label: 'Boarding'
}, {
  value: 'Day Care',
  label: 'Day Care'
}, {
  value: 'Grooming',
  label: 'Grooming'
}, {
  value: 'Other',
  label: 'Other'
}];
var LocationFinderLabels = {
  VISIT_WEBSITE: 'Visit Website',
  SEARCH_RESULTS: 'Search Results',
  NO_RESULTS: 'We could not find any locations matching your search',
  FIND_CTA: 'Find',
  REQUEST_FAILED: 'Something went wrong, Please try again later',
  LOADING: 'Loading ...'
};
var PageThankYouLabels = {
  PAGE_TITLE: 'Thank You',
  PAGE_DESCRIPTION: 'Thank You for your submission',
  HEADING: 'Thank You!',
  SUB_HEADING: 'We have received your submission.',
  DESCRIPTION: "",
  CTA_LABEL: 'Go to Homepage'
};
var Page404Labels = {
  PAGE_TITLE: '404 page',
  PAGE_DESCRIPTION: 'Requested page is not found',
  HEADING: '404',
  SUB_HEADING: "We're sorry, that page does not exist.",
  DESCRIPTION: " The page might have been deleted or there is an error in the URL. Please check the address bar for any misspellings or go to our home page through the button below.",
  CTA_LABEL: 'Return To Home Page'
};
var FORM_SUBMISSION_THANKYOU = 'Thank You for contacting us, we have received your submission.';
var FooterCopyright = {
  copyrightSymbol: '© ',
  copyright: 'Copyright'
};
var ReviewLabels = {
  WRITE_A_REVIEW: 'Write a Review',
  READ_MORE_REVIEWS: 'Read More Reviews'
};
export { globalLabels, locationLabels, navLabels, staffLabels, blogLabels, servicesBladeLabels, formLabels, formErrorMessages, emailLabels, BookFormServices, PageThankYouLabels, Page404Labels, FORM_SUBMISSION_THANKYOU, LocationFinderLabels, FooterCopyright, ReviewLabels };