import { css } from 'styled-components';
var globalTextures = [{
  key: 'leavesOrganicLightTan',
  label: 'Leaves Organic Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/static/images/textures/EarthyV1/leavesOrganicLightTan.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicGreen',
  label: 'Leaves Organic Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/static/images/textures/EarthyV1/leavesOrganicGreen.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveLightTan',
  label: 'Wave Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/static/images/textures/EarthyV1/waveLightTan.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveGreen',
  label: 'Wave Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/static/images/textures/EarthyV1/waveGreen.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainLightTan',
  label: 'Woodgrain Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/static/images/textures/EarthyV1/woodgrainLightTan.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainGreen',
  label: 'Woodgrain Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/static/images/textures/EarthyV1/woodgrainGreen.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricLightTan',
  label: 'Leaves Geometric Light Tan',
  style: css(["background-color:rgb(246,242,239);background-image:url('/static/images/textures/EarthyV1/leavesGeometricLightTan.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricGreen',
  label: 'leaves Geometric Green',
  style: css(["background-color:rgb(110,127,101);background-image:url('/static/images/textures/EarthyV1/leavesGeometricGreen.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesOrganicCream',
  label: 'Leaves Organic Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesOrganicCream.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicNavy',
  label: 'Leaves Organic Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/leavesOrganicNavy.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicBrown',
  label: 'Leaves Organic Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/leavesOrganicBrown.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveCream',
  label: 'Wave Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/waveCream.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveNavy',
  label: 'Wave Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/waveNavy.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveBrown',
  label: 'Wave Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/waveBrown.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainCream',
  label: 'Woodgrain Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesOrganicBrown.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainNavy',
  label: 'Woodgrain Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/woodgrainNavy.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainBrown',
  label: 'Woodgrain Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/woodgrainBrown.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricCream',
  label: 'Leaves Geometric Cream',
  style: css(["background-color:rgb(255,247,242);background-image:url('/static/images/textures/EarthyV2/leavesGeometricCream.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricNavy',
  label: 'Leaves Geometric Navy',
  style: css(["background-color:rgb(45,75,93);background-image:url('/static/images/textures/EarthyV2/leavesGeometricNavy.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricBrown',
  label: 'Leaves Geometric Brown',
  style: css(["background-color:rgb(114,90,72);background-image:url('/static/images/textures/EarthyV2/leavesGeometricBrown.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesOrganicStone',
  label: 'Leaves Organic Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/leavesOrganicStone.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicSalmon',
  label: 'Leaves Organic Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/leavesOrganicSalmon.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'leavesOrganicYellow',
  label: 'Leaves Organic Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/leavesOrganicYellow.svg');background-repeat:repeat;background-size:476px 476px;"])
}, {
  key: 'waveStone',
  label: 'Wave Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/waveStone.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveSalmon',
  label: 'Wave Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/waveSalmon.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'waveYellow',
  label: 'Wave Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/waveYellow.svg');background-repeat:repeat;background-size:500px 500px;"])
}, {
  key: 'woodgrainStone',
  label: 'Woodgrain Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/woodgrainStone.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainSalmon',
  label: 'Woodgrain Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/woodgrainSalmon.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'woodgrainYellow',
  label: 'Woodgrain Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/woodgrainYellow.svg');background-repeat:repeat;background-size:500px 501px;"])
}, {
  key: 'leavesGeometricStone',
  label: 'Leaves Geometric Stone',
  style: css(["background-color:rgb(218,213,198);background-image:url('/static/images/textures/EarthyV3/leavesGeometricStone.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricSalmon',
  label: 'Leaves Geometric Salmon',
  style: css(["background-color:rgb(235,165,145);background-image:url('/static/images/textures/EarthyV3/leavesGeometricSalmon.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'leavesGeometricYellow',
  label: 'Leaves Geometric Yellow',
  style: css(["background-color:rgb(229,187,100);background-image:url('/static/images/textures/EarthyV3/leavesGeometricYellow.svg');background-repeat:repeat;background-size:400px 402px;"])
}, {
  key: 'dogLavender',
  label: 'Dog Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/dogLavender.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLavender',
  label: 'Cat Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/catLavender.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLavender',
  label: 'Bones Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/bonesLavender.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLavender',
  label: 'Geometric Lavender',
  style: css(["background-color:rgb(240,236,253);background-image:url('/static/images/textures/PlayfulV1/geometricLavender.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogLightBlue',
  label: 'Dog Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/dogLightBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLightBlue',
  label: 'Cat Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/catLightBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLightBlue',
  label: 'Bones Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/bonesLightBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLightBlue',
  label: 'Geometric Light Blue',
  style: css(["background-color:rgb(222,246,255);background-image:url('/static/images/textures/PlayfulV1/geometricLightBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogLightYellow',
  label: 'Dog Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/dogLightYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catLightYellow',
  label: 'Cat Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/catLightYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesLightYellow',
  label: 'Bones Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/bonesLightYellow.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricLightYellow',
  label: 'Geometric Light Yellow',
  style: css(["background-color:rgb(254,232,188);background-image:url('/static/images/textures/PlayfulV1/geometricLightYellow.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogPink',
  label: 'Dog Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/dogPink.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catPink',
  label: 'Cat Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/catPink.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesPink',
  label: 'Bones Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/bonesPink.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricPink',
  label: 'Geometric Pink',
  style: css(["background-color:rgb(248,175,188);background-image:url('/static/images/textures/PlayfulV2/geometricPink.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogPeach',
  label: 'Dog Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/dogPeach.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catPeach',
  label: 'Cat Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/catPeach.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesPeach',
  label: 'Bones Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/bonesPeach.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricPeach',
  label: 'Geometric Peach',
  style: css(["background-color:rgb(253,203,171);background-image:url('/static/images/textures/PlayfulV2/geometricPeach.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogBabyBlue',
  label: 'Dog Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/dogBabyBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catBabyBlue',
  label: 'Cat Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/catBabyBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesBabyBlue',
  label: 'Bones Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/bonesBabyBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricBabyBlue',
  label: 'Geometric Baby Blue',
  style: css(["background-color:rgb(186,234,255);background-image:url('/static/images/textures/PlayfulV2/geometricBabyBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogBlue',
  label: 'Dog Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/dogBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catBlue',
  label: 'Cat Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/catBlue.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesBlue',
  label: 'Bones Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/bonesBlue.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricBlue',
  label: 'Geometric Blue',
  style: css(["background-color:rgb(97,155,233);background-image:url('/static/images/textures/PlayfulV3/geometricBlue.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogGreen',
  label: 'Dog Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/dogGreen.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catGreen',
  label: 'Cat Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/catGreen.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesGreen',
  label: 'Bones Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/bonesGreen.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricGreen',
  label: 'Geometric Green',
  style: css(["background-color:rgb(123,185,115);background-image:url('/static/images/textures/PlayfulV3/geometricGreen.svg');background-repeat:repeat;background-size:846px 594px;"])
}, {
  key: 'dogYellow',
  label: 'Dog Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/dogYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'catYellow',
  label: 'Cat Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/catYellow.svg');background-repeat:repeat;background-size:400px 400px;"])
}, {
  key: 'bonesYellow',
  label: 'Bones Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/bonesYellow.svg');background-repeat:repeat;background-size:1077px 539px;"])
}, {
  key: 'geometricYellow',
  label: 'Geometric Yellow',
  style: css(["background-color:rgb(252,200,117);background-image:url('/static/images/textures/PlayfulV3/geometricYellow.svg');background-repeat:repeat;background-size:846px 594px;"])
}];
export default globalTextures;