/**
 * DEPRECATED: Please use the breakpoints named export instead.
 * @file Manages the view port break points.
 * @author kkumari
 */
export default {
  mobileSm: '320px',
  mobile: '767px',
  tabletP: '768px',
  tabletL: '1023px',
  desktop: '1024px',
  desktopS: '1280px',
  desktopM: '1366px',
  desktopL: '1600px'
};
export var BREAKPOINTS = {
  xs: '20em',
  // 320px
  sm: '37.5em',
  // 600px
  md: '48em',
  // 768px
  lg: '64em',
  // 1024px
  xl: '80em',
  // 1280px
  '2xl': '91.625em',
  // 1466px
  '3xl': '100em' // 1600px
};

// The maximum value is reduced by 0.02px due to limitations of min- and max- prefixes 
// and viewports with fractional widths (https://www.w3.org/TR/mediaqueries-4/#mq-min-max).
// 0.02px instead of 0.01px due to a bug in Safari (https://bugs.webkit.org/show_bug.cgi?id=178261).
export var MAX_BREAKPOINTS = {
  xs: '19.99875em',
  // 319.98px
  sm: '37.49875em',
  // 599.98px
  md: '47.99875em',
  // 767.98px
  lg: '63.99875em',
  // 1023.98px
  xl: '79.99875em',
  // 1279.98px
  '2xl': '91.62375em',
  // 1465.98px
  '3xl': '99.99875em' // 1599.98px
};